<template>
  <div class="min-h-kyc relative">
    <div class="steps__title text-left">
      {{ $tm('onboarding.kyc.upload.mobile_number.title') }}
    </div>
    <div class="steps__subtitle text-left">
      {{ $tm('onboarding.kyc.upload.mobile_number.subtitle') }}
    </div>
    <div
      class="
        absolute
        left-1/2
        -translate-x-1/2 -translate-y-1/2
        transform
        top-1/2
        w-full
      "
    >
      <Form class="form" ref="form" v-slot="{ errors }">
        <GeneralInput
          ref="phoneInput"
          v-model:data="phone"
          v-model:phoneCode="phoneCode"
          type="phone"
          name="phone"
          :label="$tm('onboarding.kyc.upload.mobile_number.phone_number')"
          rules="required"
          :error="errors.phone"
        />
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { Form } from 'vee-validate'

import GeneralInput from '@/components/general/Input.vue'

export default {
  components: {
    GeneralInput,
    Form
  },
  emits: ['canContinue', 'sendSMS'],

  setup(props, { emit }) {
    let phone = ref('')
    let phoneCode = ref('1')
    const form = ref(null)
    let phoneInput = ref(null)

    watch(phone, async () => {
      const validation = await form.value.validate()
      if (validation.valid) {
        emit('canContinue', {
          phone_number: '+' + phoneCode.value + phone.value
        })
        emit('sendSMS', '+' + phoneCode.value + phone.value)
      } else {
        emit('canContinue', false)
      }
    })
    return { phone, phoneInput, phoneCode, form }
  }
}
</script>
